<!--
 * @Description: 角色相关岗位
 * @Author: ChenXueLin
 * @Date: 2021-10-18 14:51:13
 * @LastEditTime: 2022-07-15 16:01:10
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="postId">
              <el-input
                v-model="searchForm.postId"
                placeholder="岗位ID"
                title="岗位ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="postName">
              <el-input
                v-model="searchForm.postName"
                placeholder="岗位名称"
                title="岗位名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="postStatus">
              <e6-vr-select
                v-model="searchForm.postStatus"
                :data="jobStatus"
                placeholder="岗位状态"
                title="岗位状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-add_line"
            title="添加角色关联岗位"
            @click="handleAdd('add')"
          ></i>

          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
          ref="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'label'">
                <span
                  v-for="(item, index) in row.label"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>

              <!-- <span v-elseif="column.fieldName === 'chargeCode'">
                <el-button type="text" @click="handleGoToBillDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span> -->
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->

      <!-- 添加角色关联岗位 弹框 start -->
      <el-dialog
        v-dialogDrag
        :title="type == 'edit' ? '编辑角色关联岗位' : '添加角色关联岗位'"
        :visible.sync="addPostRoleDialog"
        width="500px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-loading="addPostRoleLoading"
        :element-loading-background="loadingBackground"
        custom-class="customer-dialog"
      >
        <el-form
          ref="addPostRoleForm"
          label-width="100px"
          :model="addPostRoleForm"
          :rules="addPostRoleRules"
        >
          <el-form-item label="所属部门" prop="userFrameworkId">
            <e6-select
              :disabled="type == 'edit'"
              v-model="addPostRoleForm.userFrameworkId"
              :dataList="treeData"
              :filterable="true"
              :slotTemplate="'tree'"
              :multiple="false"
              :issingleleaf="false"
              ref="t_select"
            ></e6-select>
          </el-form-item>
          <el-form-item label="岗位" prop="postId">
            <e6-vr-select
              :disabled="type == 'edit'"
              v-model="addPostRoleForm.postId"
              :data="userPostList"
              placeholder=""
              title="岗位"
              clearable
              :props="{
                id: 'id',
                label: 'name'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="关联角色岗位" prop="roleId">
            <e6-vr-select
              v-model="addPostRoleForm.roleId"
              :data="roleList"
              multiple
              :props="{
                id: 'id',
                label: 'name'
              }"
              placeholder="关联角色岗位"
              title="关联角色岗位"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model.trim="addPostRoleForm.remark"
              placeholder="备注"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </div>
      </el-dialog>
      <!-- 添加角色弹框  end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import handleTree from "./mixins/index";
import {
  getJobList,
  getFrameworkTree,
  getUserRoleList,
  editPostRole,
  addPostRole,
  jobList,
  postRoleEnable,
  postRoleDisEnable,
  postRoleDelete,
  getJobDetail
} from "@/api";
export default {
  name: "roleJob",
  data() {
    return {
      loading: false,
      searchForm: {
        postId: "", //岗位ID
        postName: "", //岗位名称
        postStatus: "", //岗位状态
        createdBy: "", //创建人
        startCreatedTime: "", //开始时间
        endCreatedTime: "", //结束时间
        createTime: [],
        pageIndex: 1,
        pageSize: 20
      },
      jobStatus: [
        { id: 0, label: "禁用" },
        { id: 1, label: "正常" }
      ],
      selColumnId: [], //勾选数据集合
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      total: 0,
      columnData: [
        {
          fieldName: "postId",
          display: true,
          fieldLabel: "岗位ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "postName",
          display: true,
          fieldLabel: "岗位名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "postStatus",
          display: true,
          fieldLabel: "岗位状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "roleName",
          display: true,
          fieldLabel: "关联角色",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      /**添加角色弹框 */
      addPostRoleDialog: false,
      treeData: [], //部门数据
      roleList: [], //角色下拉框
      userPostList: [], //岗位数据
      type: "", //add添加,edit编辑
      addPostRoleLoading: false,
      addPostRoleForm: {
        userFrameworkId: [],
        postId: "", //岗位id
        roleId: [],
        remark: ""
      },
      addPostRoleRules: {
        userFrameworkId: [
          {
            required: true,
            message: "请选择部门名称",
            trigger: ["blur", "change"]
          }
        ],
        postId: [
          {
            required: true,
            message: "请选择岗位名称",
            trigger: ["blur", "change"]
          }
        ],
        roleId: [
          {
            required: true,
            message: "请选择关联角色岗位",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  components: {},
  mixins: [base, listPage, listPageReszie, handleTree],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreatedTime = val[0] || "";
        this.searchForm.endCreatedTime = val[1] || "";
      }
    },
    "addPostRoleForm.userFrameworkId": {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.getJob(val[0]);
        }
      }
    }
  },
  mounted() {
    this.queryList();
    this.initData();
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        let promiseList = [getFrameworkTree(), getUserRoleList([])];
        let [treeRes, roleRes] = await Promise.all(promiseList);
        this.handleTreeData(treeRes.data ? [treeRes.data] : []);
        //用户角色
        this.roleList = this.getFreezeResponse(roleRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },

    //获取岗位列表
    async queryList() {
      try {
        let res = await getJobList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.roleName = item.roleName.join(",");
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取部门下岗位
    async getJob(id) {
      try {
        this.loading = true;
        let res = await jobList({ id });
        this.userPostList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.type = "edit";
        this.getJobDetailReq(row.postId);
        this.handleAdd("edit");
      }
    },
    //获取详情
    async getJobDetailReq(id) {
      try {
        this.loading = true;
        let res = await getJobDetail({ id });
        let detail = _.cloneDeep(res.data);
        this.addPostRoleForm.roleId = detail.roleId.map(item => {
          return item.toString();
        });
        this.addPostRoleForm.postId = detail.postId;
        this.addPostRoleForm.userFrameworkId = [detail.frameworkId];
        this.addPostRoleForm.remark = detail.remark;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.postId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择勾选要删除的数据");
        return;
      }
      this.$confirm("是否确认删除所勾选的数据？", "批量删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.postRoleDelete();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量删除请求
    async postRoleDelete() {
      try {
        let res = await postRoleDelete(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //处理启用禁用
    changeStatus(status) {
      if (this.selColumnId.length) {
        let tipTitle =
          status == 1 ? "是否确认启用勾选的角色？" : "是否确认禁用勾选的角色？";
        let title = status == 1 ? "启用角色" : "禁用角色";
        this.$confirm(tipTitle, title, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            if (status == 1) {
              this.batchEnable();
            } else {
              this.batchDisEnable();
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      } else {
        this.$message.warning("未勾选数据");
      }
    },
    //批量启用请求
    async batchEnable() {
      try {
        let res = await postRoleEnable(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //批量禁用请求
    async batchDisEnable() {
      try {
        let res = await postRoleDisEnable(this.selColumnId);
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /****添加/修改岗位*******/
    //点击添加或修改按钮
    handleAdd(type) {
      this.type = type;
      this.addPostRoleDialog = true;
    },
    //点击确定添加
    handleSubmit() {
      this.$refs.addPostRoleForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.handleAddPostRole();
          } else {
            this.handleEditPostRole();
          }
        }
      });
    },
    //添加岗位请求
    async handleAddPostRole() {
      try {
        this.addPostRoleLoading = true;
        let res = await addPostRole(this.addPostRoleForm);
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addPostRoleLoading = false;
      }
    },
    //修改岗位请求
    async handleEditPostRole() {
      try {
        this.addPostRoleLoading = true;
        let res = await editPostRole(this.addPostRoleForm);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addPostRoleLoading = false;
      }
    },
    //关闭弹框重置form
    handleClose() {
      this.addPostRoleForm = {
        userFrameworkId: [],
        postId: "", //岗位id
        roleId: [],
        remark: ""
      };
      this.$refs.addPostRoleForm.resetFields();
      this.addPostRoleDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.customer-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 220px;
    }
  }
}
</style>
